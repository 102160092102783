<template>
  <div>
    <vx-card class="mb-4">
      <div class="flex flex-wrap lg:flex-no-wrap">
        <div class="vx-row flex-grow">
          <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
            <label class="text-sm text-san-juan font-bold"
              >Fecha de inicio</label
            >
            <datepicker
              :language="langEs"
              :disabledDates="disabledDatesFrom"
              name="start-date"
              v-model="startDate"
            ></datepicker>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
            <label class="text-sm text-san-juan font-bold">Fecha de fin</label>
            <datepicker
              :language="langEs"
              :disabledDates="disabledDatesTo"
              name="end-date"
              v-model="endDate"
            ></datepicker>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/2 w-full">
            <vs-select
              label="Contratistas"
              class="w-full"
              v-model="deliverymanSelected"
              placeholder="Seleccione un contratista"
              autocomplete
            >
              <vs-select-item :key="-1" :value="null" text="Todos" />
              <vs-select-item
                :key="index"
                :value="item.user.id"
                :text="`${item.code ? item.code : 'N/A'} - ${
                  item.user.first_name + ' ' + item.user.last_name
                }`"
                v-for="(item, index) in deliverymen"
              />
            </vs-select>
          </div>
        </div>
      </div>
    </vx-card>

    <vx-card>
      <div class="flex justify-between items-center mb-4">
        <vs-input
          class="mb-4 w-full md:mb-0 mr-4"
          v-model.lazy="searchQuery"
          placeholder="Buscar..."
        />
        <vs-button
          type="border"
          class="mb-4 md:mb-0"
          @click="exportDeliverymanPoints"
          >Exportar</vs-button
        >
      </div>

      <section v-if="!loading">
        <vs-table
          ref="table"
          :max-items="first"
          :total="paginatorInfo.total"
          :data="transactionPoints"
          v-if="transactionPoints"
          no-data-text="No hay resultados"
          @change-page="() => {}"
        >
          <template slot="thead">
            <vs-th sort-key="id">Ref</vs-th>
            <vs-th class="alarm-name-column" sort-key="">Monto</vs-th>
            <vs-th sort-key="">Aliado</vs-th>
            <vs-th sort-key="">Descripción</vs-th>
            <vs-th sort-key="">Fecha</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p>{{ tr.id }}</p>
                </vs-td>
                <vs-td>
                  <p class="capitalize">{{ tr.amount }}</p>
                </vs-td>

                <vs-td>
                  <p class="capitalize">
                    {{
                      tr.userDeliveryman.deliveryman.code +
                      ' - ' +
                      tr.userDeliveryman.first_name +
                      ' ' +
                      tr.userDeliveryman.last_name
                    }}
                  </p>
                </vs-td>

                <vs-td>
                  <p class="capitalize">{{ tr.description }}</p>
                </vs-td>

                <vs-td>
                  <p class="capitalize">{{ tr.date }}</p>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>

        <footer class="flex">
          <vs-pagination
            class="mt-3"
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage"
          />
        </footer>
      </section>

      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { es } from 'vuejs-datepicker/src/locale';
import Datepicker from 'vuejs-datepicker';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
} from '@vue/composition-api';
import { ALLY_TRANSACTION_POINTS, DELIVERYMEN } from '@/graphql/queries';
import moment from 'moment';

export default defineComponent({
  components: {
    Datepicker,
  },
  setup(_, { root }) {
    const searchQuery = ref('');
    const first = ref(20);
    const currentPage = ref(1);
    const maxPageNumbers = ref(5);
    const deliverymanSelected = ref();
    const state = reactive({
      langEs: es,
      startDate: new Date(),
      endDate: new Date(),
    });

    const { result: deliverymenResult } = useQuery(DELIVERYMEN);
    const deliverymen = useResult(
      deliverymenResult,
      [],
      (data) => data.deliverymen
    );

    const sDate = computed(() => moment(state.startDate).format('YYYY-MM-DD'));
    const eDate = computed(() => moment(state.endDate).format('YYYY-MM-DD'));
    const { result: pointsResult, loading } = useQuery(
      ALLY_TRANSACTION_POINTS,
      {
        first: first,
        page: currentPage,
        search: searchQuery,
        user_deliveryman_id: deliverymanSelected,
        startDate: sDate,
        endDate: eDate,
      }
    );
    const transactionPoints = useResult(
      pointsResult,
      [],
      (data) => data.transactionPoints.data
    );
    const paginatorInfo = useResult(
      pointsResult,
      [],
      (data) => data.transactionPoints.paginatorInfo
    );

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );
    const disabledDatesTo = computed(() => {
      return { to: new Date(state.startDate) };
    });
    const disabledDatesFrom = computed(() => {
      return { from: new Date(state.endDate) };
    });

    const resetFilter = () => {
      deliverymanSelected.value = '';
    };

    const exportDeliverymanPoints = () => {
      const fileName = 'puntos.xlsx';
      root.$http
        .post(
          'api/deliverymen/points/export',
          {
            startDate: sDate.value,
            endDate: eDate.value,
            deliveryman_id: deliverymanSelected.value,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    return {
      searchQuery,
      transactionPoints,
      totalPages,
      maxPageNumbers,
      loading,
      first,
      currentPage,
      paginatorInfo,
      disabledDatesTo,
      disabledDatesFrom,
      deliverymen,
      deliverymanSelected,
      resetFilter,
      exportDeliverymanPoints,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss"></style>
